import React from "react";

import "./style.scss";
import { connect } from "react-redux";
import { compose, withState, withHandlers } from "recompose";
import AttributeList from "./AttributeList";
import { Grid, IconButton } from "@material-ui/core";
import LeftContent from "./LeftContent";
import Map from "./Map";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { ToggleShowHideSideContent } from "../../services/actions/GisAction";
import Loading from "../../shared/components/Loading";
import { getMapTransitionPoints } from "../../shared/utils/objectExtensions";

const Gis = ({
  loading,
  surveyedAllocated,
  showSideContent,
  showAttributeList,
  ToggleSideContent,
  dataDetailSelected,
  showSurveyedDetail,
  closeSurveyedDetail,
  shouldShowDetail,
}) => {
  return (
    <Grid container>
      {loading && !surveyedAllocated && (
        <div className="loading-container">
          <Loading />
        </div>
      )}
      {showSideContent ? (
        <Grid item md={3} xs={12} className="map-form-section">
          <LeftContent closeSurveyedDetail={closeSurveyedDetail} />
          <ToggleSideButton
            showSideContent={showSideContent}
            toggleSideContent={ToggleSideContent}
          />
        </Grid>
      ) : (
        <ToggleSideButton
          showSideContent={showSideContent}
          toggleSideContent={ToggleSideContent}
        />
      )}
      <Grid item md={showSideContent ? 9 : 12} xs={12}>
        {showAttributeList && (
          <AttributeList showSurveyedDetail={showSurveyedDetail} />
        )}
        <Map
          dataDetailSelected={dataDetailSelected}
          showSurveyedDetail={showSurveyedDetail}
          closeSurveyedDetail={closeSurveyedDetail}
          shouldShowDetail={shouldShowDetail}
        />
      </Grid>
    </Grid>
  );
};

const ToggleSideButton = ({ showSideContent, toggleSideContent }) => (
  <IconButton
    className={`toggle-show-hide-button ${!showSideContent && "hide"}`}
    onClick={() => toggleSideContent()}
  >
    {showSideContent ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
  </IconButton>
);

export default compose(
  withState("dataDetailSelected", "setDataDetailSelected", null),
  withState("shouldShowDetail", "setShouldShowDetail", false),
  connect(
    (state) => ({
      surveyedAllocated: state.road.surveyedAllocated,
      showAttributeList: state.road.showAttributeList,
      showSideContent: state.gis.showSideContent,
      loading: state.road.loading,
      surveyedMapType: state.gis.surveyedMapType,
    }),
    (dispatch) => ({
      ToggleSideContent: () => dispatch(ToggleShowHideSideContent()),
    })
  ),
  withHandlers({
    showSurveyedDetail:
      ({ setDataDetailSelected, setShouldShowDetail, surveyedMapType }) =>
      (
        startPos,
        startDate,
        polylines,
        surveyedData,
        lastPositionData = null
      ) => {
        setShouldShowDetail(true);
        setDataDetailSelected({
          startPosition: startPos,
          startDate,
          polylines,
          lastPositionData,
          transitionObject: getMapTransitionPoints(
            surveyedData,
            polylines,
            startPos,
            lastPositionData,
            surveyedMapType
          ),
        });
      },
    closeSurveyedDetail:
      ({ setShouldShowDetail, setDataDetailSelected }) =>
      (clearData) => {
        setShouldShowDetail(false);
        if (clearData) setDataDetailSelected(null);
      },
  })
)(Gis);
