import React, { useEffect } from "react";
import { Grid, InputLabel, Tooltip, Button, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import {
  SelectDateSurveyed,
  ShowAttributeList,
  GetDateThatHaveData,
  ResetFilterDate,
  SelectUserFilter,
  GetRoadSurveyed,
  SetUserFilter,
} from "../../../services/actions/RoadAction";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Toast } from "../../../shared/utils/objectExtensions";
import moment from "moment";
import { Clear as ClearIcon, Add as AddIcon } from "@material-ui/icons";
import { withTranslation, Trans } from "react-i18next";
import { GetUsers } from "../../../services/actions/UserAction";
import { GetSurfaces } from "../../../services/actions/SurfaceAction";

const FilterCard = ({
  selectedDates,
  SetSelectedDate,
  GetDates,
  datesHaveData,
  ResetFilter,
  isAdmin,
  userFilter,
  SetUserId,
  users,
  loggedUser,
}) => {
  useEffect(() => {
    if (loggedUser?.id != 1) {
      SetUserId(loggedUser.id);
    } else if (users && users.length > 0 && !userFilter) {
      SetUserId(users[users.length - 1].id);
    }
  }, [users]);

  return (
    <div className="filter-card">
      <Grid container spacing={3}>
        <Grid item md={12} style={{ width: "100%" }}>
          {isAdmin && users && users.length > 0 && (
            <>
              <InputLabel
                htmlFor="user"
                style={{ color: "white", paddingBottom: "5px" }}
              >
                <Trans>user</Trans>
              </InputLabel>
              <FormControl className="field" variant="outlined">
                <Select
                  native
                  name="user"
                  value={userFilter}
                  onChange={(e) => SetUserId(e.target.value)}
                >
                  {loggedUser && (
                    <option key={loggedUser.id} value={loggedUser.id}>
                      {loggedUser.userName}
                    </option>
                  )}
                  {users.map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.userName} - {x.firstName} {x.lastNameF}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          <InputLabel
            htmlFor="date-filter"
            style={{ color: "white", paddingBottom: "5px", paddingTop: "5px" }}
          >
            <Trans>date</Trans>
          </InputLabel>
          {selectedDates.map((selectedDate, idx) => (
            <Grid key={idx} container>
              <Grid item xs={11}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    name="date-filter"
                    autoOk={true}
                    allowKeyboardControl={false}
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id={`date-picker-inline-${idx}`}
                    value={selectedDate}
                    onChange={(date) => {
                      if (date == "Invalid Date") {
                        Toast.fire({
                          type: "warning",
                          title: "Invalid Date",
                        });
                      } else SetSelectedDate(idx, date);
                    }}
                    KeyboardButtonProps={{ "aria-label": "change date" }}
                    onMonthChange={(date) =>
                      GetDates(date.getMonth() + 1, date.getFullYear())
                    }
                    onYearChange={(date) =>
                      GetDates(date.getMonth() + 1, date.getFullYear())
                    }
                    onOpen={() => {
                      const today = moment();
                      GetDates(today.month() + 1, today.year());
                    }}
                    renderDay={(
                      day,
                      selectedDate,
                      dayInCurrentMonth,
                      dayComponent
                    ) => {
                      const dateHasData =
                        dayInCurrentMonth &&
                        datesHaveData &&
                        datesHaveData.includes(day.getDate());
                      const content = (
                        <div
                          className={`${dateHasData ? "date-has-data" : ""} ${
                            selectedDate.getDate() === day.getDate() &&
                            selectedDate.getMonth() === day.getMonth()
                              ? "selected-date"
                              : ""
                          }`}
                        >
                          {dayComponent}
                        </div>
                      );
                      return dateHasData ? (
                        <Tooltip title={<Trans>has_data</Trans>}>
                          {content}
                        </Tooltip>
                      ) : (
                        content
                      );
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={1}>
                <Button
                  size="small"
                  className="delete-btn"
                  startIcon={<ClearIcon />}
                  onClick={() => SetSelectedDate(idx, null)}
                />
              </Grid>
            </Grid>
          ))}
          <Grid container>
            <Grid item xs={selectedDates.length > 0 ? 11 : 12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk={true}
                  allowKeyboardControl={false}
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline-add"
                  value={null}
                  onChange={(date) => {
                    if (date == "Invalid Date") {
                      Toast.fire({
                        type: "warning",
                        title: "Invalid Date",
                      });
                    } else SetSelectedDate(selectedDates.length, date);
                  }}
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  onMonthChange={(date) =>
                    GetDates(date.getMonth() + 1, date.getFullYear())
                  }
                  onYearChange={(date) =>
                    GetDates(date.getMonth() + 1, date.getFullYear())
                  }
                  onOpen={() => {
                    const today = moment();
                    GetDates(today.month() + 1, today.year());
                  }}
                  renderDay={(
                    day,
                    selectedDate,
                    dayInCurrentMonth,
                    dayComponent
                  ) => {
                    const dateHasData =
                      dayInCurrentMonth &&
                      datesHaveData &&
                      datesHaveData.includes(day.getDate());
                    const content = (
                      <div
                        className={`${dateHasData ? "date-has-data" : ""} ${
                          selectedDate.getDate() === day.getDate() &&
                          selectedDate.getMonth() === day.getMonth()
                            ? "selected-date"
                            : ""
                        }`}
                      >
                        {dayComponent}
                      </div>
                    );
                    return dateHasData ? (
                      <Tooltip title={<Trans>has_data</Trans>}>
                        {content}
                      </Tooltip>
                    ) : (
                      content
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {selectedDates.length > 0 && (
              <Grid item xs={1}>
                <Button
                  disabled
                  size="small"
                  className="delete-btn"
                  style={{ color: "white" }}
                  startIcon={<AddIcon />}
                />
              </Grid>
            )}
          </Grid>
          {selectedDates.length > 0 && (
            <div className="clear-dates" onClick={() => ResetFilter()}>
              <Button size="small" startIcon={<ClearIcon />}>
                <Trans>clear_filter</Trans>
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default compose(
  withTranslation("translations"),
  connect(
    (state) => ({
      selectedDates: state.road.selectedDates,
      uploading: state.road.uploading,
      isAdmin: state.auth.isAdmin,
      loggedUser: state.auth.user,
      datesHaveData: state.road.datesHaveData,
      userFilter: state.road.userFilter,
      users: state.user.users,
    }),
    (dispatch) => ({
      SetUserId: (userId) => dispatch(SelectUserFilter(userId)),
      ResetUserFilter: () => dispatch(SetUserFilter(null)),
      SetSelectedDate: (idx, date) => dispatch(SelectDateSurveyed(idx, date)),
      ShowAttributeList: () => dispatch(ShowAttributeList()),
      GetDates: (month, year) => dispatch(GetDateThatHaveData(month, year)),
      ResetFilter: () => dispatch(ResetFilterDate()),
      getUsers: () => dispatch(GetUsers()),
      getSurfaces: () => dispatch(GetSurfaces()),
    })
  ),
  lifecycle({
    componentDidMount() {
      if (this.props.isAdmin) {
        this.props.getUsers();
      } else {
        this.props.SetUserId(this.props.loggedUser.id);
      }
    },
    componentWillUnmount() {
      this.props.ResetUserFilter();
    },
  })
)(FilterCard);
