import React from "react";
import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { FilterRank } from "../../../services/actions/RoadAction";

import { Box, Button, Tooltip } from "@material-ui/core";
import { Clear as ClearIcon } from "@material-ui/icons";
import { CompareArray } from "../../../shared/utils/objectExtensions";
import { withTranslation, Trans } from "react-i18next";
import { GetSurfaces } from "../../../services/actions/SurfaceAction";

const DeteriorationRank = ({
  rankFilters,
  FilterRank,
  closeSurveyedDetail,
  surfaces,
}) => {
  return (
    <div className="deterioration-rank-card">
      <p>
        <Trans>deterioration_rank</Trans>
      </p>
      {surfaces.map((x, idx) => (
        <div
          key={idx}
          className={`distress-label hover ${
            rankFilters &&
            rankFilters.length > 0 &&
            rankFilters.find((rankFilter) =>
              CompareArray(rankFilter, [x.value])
            ) &&
            "selected"
          }`}
          onClick={() => {
            closeSurveyedDetail();
            FilterRank([x.value]);
          }}
        >
          <Box width={18}>
            <div
              style={{ backgroundColor: x.color }}
              className="distress-dot"
            />
          </Box>
          <Box
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Tooltip title={x.title} placement="top">
              <h4
                dangerouslySetInnerHTML={{
                  __html: `${x.value} &nbsp; ${x.title}`,
                }}
              />
            </Tooltip>
          </Box>
        </div>
      ))}
      {rankFilters && rankFilters.length > 0 && (
        <div
          className="distress-label"
          onClick={() => {
            closeSurveyedDetail();
            FilterRank(null);
          }}
        >
          <Button size="small" startIcon={<ClearIcon />}>
            <Trans>clear_filter</Trans>
          </Button>
        </div>
      )}
    </div>
  );
};
export default compose(
  withTranslation("translations"),
  connect(
    (state) => ({
      rankFilters: state.road.rankFilters,
      surfaces: state.surface.surfaces,
    }),
    (dispatch) => ({
      FilterRank: (distress) => dispatch(FilterRank(distress)),
      getSurfaceConditions: () => dispatch(GetSurfaces()),
    })
  ),
  lifecycle({
    componentDidMount() {
      // this.props.getSurfaceConditions();
    },
  })
)(DeteriorationRank);
