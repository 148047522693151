import Swal from "sweetalert2";
import { MAIN_COLOR } from "./constant";

export const CompareArray = (array1, array2) =>
  array1.length === array2.length &&
  array1.every((value, index) => value === array2[index]);

export const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  animation: false,
  timer: 3000,
});

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

export const coordinateToDistance = (points) => {
  var result = 0;
  var lastPoint = points[0];
  for (let i = 1; i < points.length; i++) {
    const point = points[i];
    result += getDistanceFromLatLonInKm(
      lastPoint[0],
      lastPoint[1],
      point[0],
      point[1]
    );
    lastPoint = point;
  }

  return result;
};

export const runSnapToRoad = (finalArray) => {
  return "";
};

export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const getUnique = (array) => {
  var uniqueArray = [];
  for (var i = 0; i < array.length; i++) {
    if (uniqueArray.indexOf(array[i]) === -1) {
      uniqueArray.push(array[i]);
    }
  }
  return uniqueArray;
};

export const getGeoJsonFromSurveyedData = (
  surfaces,
  surveyedData,
  surveyedMapType
) => {
  const sourceData = surveyedData.filter(
    (x) => x.polylines && x.polylines.length > 1
  );
  const colorByDistress = (value) => {
    var condition = (surfaces || []).find((x) => x.value == value);
    if (condition) {
      return condition.color;
    }

    return MAIN_COLOR;
  };

  if (surveyedMapType === "line") {
    return sourceData.map((x) => ({
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: x.polylines.map((p) => [p[1], p[0]]),
      },
      properties: {
        uId: x.userId,
        Date: x.surveyedDate,
        Distress: x.distressAverage,
        Start: `${x.polylines[0][0]} - ${x.polylines[0][1]}`,
        End: `${
          x.polylines[x.polylines.length > 5 ? 5 : x.polylines.length - 1][0]
        } - ${
          x.polylines[x.polylines.length > 5 ? 5 : x.polylines.length - 1][1]
        }`,
        color: `${colorByDistress(x.distressAverage)}`,
        st_name: x.routeName,
        picPath: x.picPaths ? JSON.stringify(x.picPaths) : "",
      },
    }));
  }
  const pointDatas = [];
  sourceData.map((x) =>
    x.polylines.map((p, idx) => {
      const existingPoint = pointDatas.find(
        (x) =>
          x.geometry.coordinates[0] == p[1] && x.geometry.coordinates[1] == p[0]
      );

      if (!existingPoint) {
        var picPath =
          x.picPaths && x.picPaths.length > idx ? x.picPaths[idx] : "";
        const pointDistress = x.polylineDistress.find(
          (d) => d.polylines === JSON.stringify(p)
        );
        const distressAverage =
          pointDistress?.distressAverage || x.distressAverage;
        pointDatas.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [p[1], p[0]],
          },
          properties: {
            uId: x.userId,
            Date: x.surveyedDate,
            DistressAverage: distressAverage,
            Lat: `${p[0]}`,
            Long: `${p[1]}`,
            st_name: x.routeName,
            picPath: picPath,
            color: `${colorByDistress(distressAverage)}`,
          },
        });
      }
    })
  );
  return pointDatas;
};

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export const HasPermissions = (menuType, permissionType) => {
  const permissionsObj = JSON.parse(
    sessionStorage.getItem("userLogged")
  ).permissions;

  if (!permissionsObj) return false;
  const menuPermission = permissionsObj.find((x) => x.id === menuType.value);

  return menuPermission && menuPermission.permissionTypes
    ? menuPermission.permissionTypes.includes(permissionType?.name)
    : false;
};

export const getMapTransitionPoints = (
  surveyedData,
  polylines,
  selectedPoint,
  lastPositionData
) => {
  return null;
};
