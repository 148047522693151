import React from "react";
import MaterialTable from "material-table";
import { Trans, withTranslation } from "react-i18next";
import {
  TablePagination,
  Button,
  FormControl,
  Select,
  InputLabel,
  Box,
} from "@material-ui/core";
import { compose, lifecycle, withState } from "recompose";
import { connect } from "react-redux";
import {
  GetSurfaces,
  DeleteSurface,
  SetSelectedUser,
} from "../../services/actions/SurfaceAction";
import { useHistory } from "react-router-dom";
import { Add as AddIcon } from "@material-ui/icons";
import {
  MAIN_COLOR,
  DANGER_COLOR,
  MenuType,
  PermissionType,
} from "../../shared/utils/constant";
import { HasPermissions } from "../../shared/utils/objectExtensions";
import { ColorButton } from "material-ui-color";
import Swal from "sweetalert2";
import i18n from "../../localization/i18n";
import { GetUsers } from "../../services/actions/UserAction";
import Loading from "../../shared/components/Loading";

function SurfaceList({
  loading,
  isAdmin,
  loggedUser,
  users,
  surfaces,
  deleteSurface,
  selectedUser,
  setSelectedUser,
  getData,
}) {
  let history = useHistory();
  const actionClicked = (link) => {
    history.push(link);
  };
  return (
    <div className="road-information-container">
      {loading && (
        <div className="loading-container">
          <Loading />
        </div>
      )}
      {isAdmin && (
        <Box marginBottom={2}>
          <InputLabel
            htmlFor="user"
            style={{ color: "black", paddingBottom: "5px" }}
          >
            <Trans>user</Trans>
          </InputLabel>
          <FormControl className="field" variant="outlined">
            <Select
              native
              name="user"
              value={selectedUser}
              onChange={(e) => {
                setSelectedUser(e.target.value);
                getData(e.target.value);
              }}
            >
              {loggedUser && (
                <option key={loggedUser.id} value={loggedUser.id}>
                  Default
                </option>
              )}
              {users.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.userName} - {x.firstName} {x.lastNameF}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      <div>
        {HasPermissions(MenuType.SurfaceCondition, PermissionType.Add) && (
          <Button
            variant="contained"
            className="upload-btn"
            onClick={() => actionClicked("surfaces/new")}
          >
            <AddIcon /> {i18n.t("add")}
          </Button>
        )}
        <MaterialTable
          title={i18n.t("manage_surface_condition")}
          columns={[
            { title: i18n.t("title"), field: "title" },
            {
              title: i18n.t("description"),
              field: "description",
            },
            {
              title: i18n.t("value"),
              field: "value",
              cellStyle: {
                textAlign: "left",
              },
              headerStyle: {
                textAlign: "left",
              },
            },
            {
              title: i18n.t("color"),
              field: "color",
              render: (rowData) => (
                <>
                  <ColorButton color={rowData.color} tooltip={rowData.color} />
                </>
              ),
            },
          ]}
          data={JSON.parse(JSON.stringify(surfaces))}
          components={{
            Pagination: (props) => <TablePagination {...props} />,
          }}
          actions={[
            HasPermissions(MenuType.SurfaceCondition, PermissionType.Edit) && {
              icon: "edit",
              tooltip: "Edit Surface",
              iconProps: { style: { color: MAIN_COLOR } },
              onClick: (_e, rowData) => actionClicked(`surfaces/${rowData.id}`),
            },
            HasPermissions(
              MenuType.SurfaceCondition,
              PermissionType.Delete
            ) && {
              icon: "delete",
              tooltip: "Delete Surface",
              iconProps: { style: { color: DANGER_COLOR } },
              onClick: (_e, rowData) => deleteSurface(rowData.id),
            },
          ].filter((x) => x)}
          options={{
            pageSize: 5,
            maxBodyHeight: "calc(100vh - 244px)",
            sorting: false,
            draggable: false,
          }}
        />
      </div>
    </div>
  );
}
export default compose(
  withTranslation("translations"),
  withState("selectedUser", "setSelectedUser", 1),
  connect(
    (state) => ({
      loading: state.surface.loading,
      surfaces: state.surface.surfaces,
      isAdmin: state.auth.isAdmin,
      loggedUser: state.auth.user,
      users: state.user.users,
      selectedUser: state.surface.selectedUser,
    }),
    (dispatch) => ({
      getData: (userId) => dispatch(GetSurfaces(userId)),
      getUsers: () => dispatch(GetUsers()),
      deleteSurface: (id) => {
        Swal.fire({
          title: "Are you sure you want to delete this item?",
          showCancelButton: true,
          confirmButtonText: `Yes`,
        }).then((result) => {
          if (result.value) {
            dispatch(DeleteSurface(id));
          }
        });
      },
      setSelectedUser: (userId) => dispatch(SetSelectedUser(userId)),
    })
  ),
  lifecycle({
    componentDidMount() {
      this.props.getUsers();
      this.props.getData(this.props.selectedUser);
    },
  })
)(SurfaceList);
