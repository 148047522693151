import React from "react";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { MAIN_COLOR } from "../../shared/utils/constant";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ImageIcon from "@material-ui/icons/Image";
import UploadButton from "../RoadSurveyed/UploadButton";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: MAIN_COLOR,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  fileContainer: {
    backgroundColor: theme.palette.background.paper,
    height: "calc(100vh - 80px)",
    overflow: "auto",
  },
}));

function ManageS3Files({ loading, open, handleClose, files, userId }) {
  const classes = useStyles();
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleDownload = async (url, filename) => {
    const response = await fetch("https://sheltered-dawn-68271.herokuapp.com/" + url);
    response.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
    });
  };

  return (
    open && (
      <Dialog
        fullScreen
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Manage files
            </Typography>
            <UploadButton
              title="Add file"
              noImageUpload={true}
              userId={userId}
            />
          </Toolbar>
        </AppBar>
        <List>
          {loading ? (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <div className={classes.fileContainer}>
              {files && files.length ? (
                <List>
                  {[...files].reverse().map((x, idx) => (
                    <ListItem key={idx}>
                      <ListItemAvatar>
                        {x.key.includes("jpg") ? (
                          <ImageIcon style={{ color: MAIN_COLOR }} />
                        ) : (
                          <InsertDriveFileIcon color="primary" />
                        )}
                      </ListItemAvatar>
                      <Button
                        style={{ textTransform: "initial" }}
                        onClick={() =>
                          handleDownload(
                            `https://${x.bucketName}.s3-us-east-2.amazonaws.com/${x.key}`,
                            `${
                              x.key.includes("csv")
                                ? x.key.split(".csv")[0] + ".csv"
                                : x.key
                            }`
                          )
                        }
                      >
                        <ListItemText primary={x.key} />
                      </Button>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Box textAlign="center">
                  <Typography>There is no data</Typography>
                </Box>
              )}
            </div>
          )}
        </List>
      </Dialog>
    )
  );
}

export default compose(
  withTranslation("translations"),
  connect(
    (state) => ({
      loading: state.setting.loading,
      files: state.setting.files,
    }),
    (dispatch) => ({
      //   getData: () => dispatch(GetSetting()),
    })
  ),
  lifecycle({
    componentDidMount() {
      //   this.props.getData();
    },
  })
)(ManageS3Files);
