import React from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { CloseSharp, List as ListIcon } from "@material-ui/icons";
import { compose } from "recompose";
import { ShowAttributeList } from "../../../services/actions/RoadAction";
import { withTranslation, Trans } from "react-i18next";

const TopAction = ({
  showAttributeList,
  TogleShowAttributeList,
  closeSurveyedDetail,
}) => {
  return (
    <div className="top-actions">
      <Button
        variant="contained"
        className="main-btn"
        onClick={() => {
          TogleShowAttributeList();
          if (!showAttributeList) {
            closeSurveyedDetail();
          }
        }}
      >
        {!showAttributeList ? <ListIcon /> : <CloseSharp />}{" "}
        {!showAttributeList ? <Trans>show</Trans> : <Trans>hide</Trans>}{" "}
        <Trans>attributes</Trans>
      </Button>
    </div>
  );
};

export default compose(
  withTranslation("translations"),
  connect(
    (state) => ({
      showAttributeList: state.road.showAttributeList,
    }),
    (dispatch) => ({
      TogleShowAttributeList: () => dispatch(ShowAttributeList()),
    })
  )
)(TopAction);
