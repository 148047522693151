import React from "react";
import { compose, lifecycle, withState } from "recompose";
import { connect } from "react-redux";
import {
  GetRoadSurveyed,
  SetRankFilter,
} from "../../../services/actions/RoadAction";
import UploadForm from "./UploadForm";

import "./style.scss";
import FilterCard from "./FilterCard";
import TopAction from "./TopAction";
import DeteriorationRank from "./DeteriorationRank";

const GisMapDetail = ({ closeSurveyedDetail }) => {
  return (
    <div className="gis-map-detail">
      <div>
        <FilterCard />
        <TopAction closeSurveyedDetail={closeSurveyedDetail} />
        <DeteriorationRank closeSurveyedDetail={closeSurveyedDetail} />
        <UploadForm />
      </div>
    </div>
  );
};

export default compose(
  withState("toggleSideContent", "setToggleSideContent", true),
  connect(
    (_) => ({}),
    (dispatch) => ({
      GetRoadSurveyed: () => dispatch(GetRoadSurveyed()),
      SetRankFilter: (data) => dispatch(SetRankFilter(data)),
    })
  ),
  lifecycle({
    componentDidMount() {
      // this.props.GetRoadSurveyed();
    },
  })
)(GisMapDetail);
