import React, { useRef } from "react";
import { Map as LeafletMap, Polyline, Circle } from "react-leaflet";
import { connect } from "react-redux";

import { compose, withState, withHandlers, lifecycle } from "recompose";
import {
  BaseMapTile,
  MAIN_COLOR,
  MenuType,
  PermissionType,
} from "../../shared/utils/constant";
import FullscreenControl from "react-leaflet-fullscreen";
import { get } from "lodash";
import { geolocated } from "react-geolocated";
import Control from "react-leaflet-control";
import { Button, ButtonGroup } from "@material-ui/core";
import { CloudDownload as DownloadIcon } from "@material-ui/icons";
import { setSurveyedMapType } from "../../services/actions/GisAction";
import moment from "moment";
import shpwrite from "shp-write";
import {
  getGeoJsonFromSurveyedData,
  HasPermissions,
} from "../../shared/utils/objectExtensions";
import { ShowPermissionRequired } from "../../services/actions/SystemAction";
import { GetCheckedPoints } from "../../services/actions/RoadAction";

const Map = ({
  data,
  coords,
  surveyedMapType,
  setMapType,
  dowloadShapeFile,
  checkedPoints,
  surfaces,
}) => {
  const mapEl = useRef(null);
  const center = get(data, "[0].polylines[0]", [
    get(coords, "latitude", 30.55435),
    get(coords, "longitude", -91.03677),
  ]);

  const mappZoom = 16;

  const colorByDistress = (value) => {
    var condition = (surfaces || []).find((x) => x.value == value);
    if (condition) {
      return condition.color;
    }

    return MAIN_COLOR;
  };

  return (
    <LeafletMap
      ref={mapEl}
      center={center}
      zoom={mappZoom}
      maxZoom={18}
      style={{ height: `calc(100% - 64px)` }}
    >
      <BaseMapTile />
      <FullscreenControl position="topright" />
      <Control position="topright">
        <ButtonGroup size="small" className="map-panel-type">
          <Button
            className={`${surveyedMapType === "line" ? "type-selected" : ""}`}
            onClick={() => setMapType("line")}
          >
            Line
          </Button>
          <Button
            className={`${surveyedMapType === "point" ? "type-selected" : ""}`}
            onClick={() => setMapType("point")}
          >
            Point
          </Button>
        </ButtonGroup>
      </Control>
      {data && data.length > 0 && (
        <Control position="topright">
          <Button
            variant="outlined"
            size="small"
            className="btn-download-shape-file"
            onClick={() => dowloadShapeFile(data)}
          >
            <DownloadIcon />
          </Button>
        </Control>
      )}
      {data &&
        data.map((x, idx) => {
          const color = colorByDistress(x.distressAverage);
          return surveyedMapType === "line" ? (
            <Polyline
              key={idx}
              color={colorByDistress(x.distressAverage)}
              positions={x.snappedPoints}
            />
          ) : (
            x.polylines.map((p, idx) => (
              <Circle
                key={idx}
                className={`point-surveyed-type ${
                  checkedPoints.find(
                    (x) => JSON.stringify(x) === JSON.stringify(p)
                  )
                    ? "checked"
                    : ""
                }`}
                center={p}
                radius={1.5}
                color={color}
                fillColor={color}
              ></Circle>
            ))
          );
        })}
    </LeafletMap>
  );
};

export default compose(
  withState("zoom", "setZoom", 16),
  withState("center", "setCenter", [39.94792279, -79.99223758]),
  geolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  }),
  connect(
    (state) => ({
      surveyedAllocated: state.road.surveyedAllocated,
      showAttributeList: state.road.showAttributeList,
      surveyedMapType: state.gis.surveyedMapType,
      checkedPoints: state.road.checkedPoints,
      surfaces: state.surface.surfaces,
    }),
    (dispatch) => ({
      setMapType: (value) => dispatch(setSurveyedMapType(value)),
      showPermissionRequired: () => dispatch(ShowPermissionRequired()),
      getCheckedPoint: () => dispatch(GetCheckedPoints()),
    })
  ),
  withHandlers({
    dowloadShapeFile:
      ({ surveyedMapType, showPermissionRequired, surfaces }) =>
      (surveyedData) => {
        if (!HasPermissions(MenuType.SurveyedList, PermissionType.Download)) {
          return showPermissionRequired();
        }
        const fileName = `pms_shapefile_${
          surveyedMapType === "line" ? "line_" : "point_"
        }${moment().format("DDMMYYYYhhmmss")}`;
        var options = {
          file: fileName,
          folder: fileName,
          types: {
            point: "points",
            polygon: "polygons",
            polyline: "polylines",
          },
        };
        shpwrite.download(
          {
            type: "FeatureCollection",
            features: [
              ...getGeoJsonFromSurveyedData(surfaces, surveyedData, surveyedMapType),
            ],
          },
          options
        );
      },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getCheckedPoint();
    },
  })
)(Map);
