import React from "react";
import { Field, reduxForm, change, Form, formValueSelector } from "redux-form";
import TextField from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Button, Grid, CircularProgress } from "@material-ui/core";
import {
  Save as SaveIcon,
  Cancel as CancelIcon,
  List as ListIcon,
} from "@material-ui/icons";
import { Trans } from "react-i18next";
import * as Yup from "yup";
import validator from "../../shared/utils/validator";
import { get } from "lodash";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import "./style.scss";
import {
  GetSurfaceDetail,
  UpdateSurfaceDetail,
  ClearSurfaceData,
  GetSurfaces,
} from "../../services/actions/SurfaceAction";
import { ColorPicker } from "material-ui-color";
import Swal from "sweetalert2";

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  renderInput,
  ...custom
}) => (
  <FormControl
    variant="outlined"
    className="field"
    error={error ? true : false}
  >
    <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
    {renderInput ? (
      renderInput({ input, custom })
    ) : (
      <TextField
        placeholder={label}
        error={touched && invalid}
        {...input}
        {...custom}
      />
    )}
    <FormHelperText>{error}</FormHelperText>
  </FormControl>
);

const SurfaceForm = ({
  loading,
  saving,
  handleSubmit,
  onSubmit,
  pristine,
  onRestForm,
  submitting,
}) => {
  let history = useHistory();

  return (
    <div className="surface-form">
      <h1>Surface condition form</h1>
      {loading ? (
        <CircularProgress />
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={10}>
            <Grid item md={12}>
              <div>
                <Field
                  name="title"
                  component={renderTextField}
                  label="Title"
                  labelWidth={80}
                  disabled={saving}
                />
                <Field
                  name="description"
                  component={renderTextField}
                  label="Description"
                  labelWidth={80}
                  disabled={saving}
                />
                <Field
                  name="value"
                  type="number"
                  component={renderTextField}
                  label="Value"
                  labelWidth={80}
                  disabled={saving}
                />
                <Field
                  name="color"
                  component={renderTextField}
                  renderInput={({ input, custom }) => (
                    <ColorPicker
                      defaultValue="#000"
                      {...custom}
                      value={input.value}
                      onChange={(color) => {
                        input.onChange(color?.hex ? `#${color.hex}` : color);
                      }}
                    />
                  )}
                  labelWidth={40}
                  disabled={saving}
                />
              </div>
            </Grid>
          </Grid>
          <div className="actions">
            <Button
              type="submit"
              size="small"
              startIcon={saving ? null : <SaveIcon />}
              disabled={pristine || submitting || saving}
              className="main-btn"
            >
              {saving ? (
                <CircularProgress color="white" size={22} />
              ) : (
                <Trans>save</Trans>
              )}
            </Button>
            <Button
              size="small"
              variant="outlined"
              startIcon={<CancelIcon />}
              disabled={pristine || submitting || saving}
              onClick={onRestForm}
            >
              <Trans>cancel</Trans>
            </Button>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              startIcon={<ListIcon />}
              onClick={() => history.push("/surfaces")}
            >
              <Trans>back_to_list</Trans>
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

const validateSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  value: Yup.number().required("Value is required"),
  color: Yup.string().required("Color is required"),
});

export default compose(
  reduxForm({
    form: "SurfaceForm",
    asyncValidate: validator(validateSchema),
  }),
  withState("isEdit", "setIsEdit", false),
  withState("surfaceState", "setSurfaceState", null),
  withState("stateSaving", "setStateSaving", false),
  connect(
    (state) => ({
      loading: state.surface.loading,
      saving: state.surface.saving,
      isAdmin: state.auth.isAdmin,
      surfaceDetail: state.surface.surfaceDetail,
      success: state.system.success,
      surfaces: state.surface.surfaces,
      selectedUser: state.surface.selectedUser,
    }),
    (dispatch) => ({
      getData: () => dispatch(GetSurfaces()),
      getSurfaceDetail: (id) => dispatch(GetSurfaceDetail(id)),
      updateSurfaceDetail: (data) => dispatch(UpdateSurfaceDetail(data)),
      clearSurfaceDetail: () => dispatch(ClearSurfaceData()),
      changeFieldValue: (field, value) =>
        dispatch(change("SurfaceForm", field, value)),
    })
  ),
  withHandlers({
    onSubmit:
      ({
        match,
        updateSurfaceDetail,
        setStateSaving,
        surfaces,
        selectedUser,
      }) =>
      (values) => {
        setStateSaving(true);
        const id = get(match, "params.id");

        if (
          surfaces.find(
            (x) =>
              x.id != id && x.title.toLowerCase() === values.title.toLowerCase()
          )
        ) {
          Swal.fire({
            title: "Validation fail",
            text: `Title '${values.title}' is duplicated`,
            type: "error",
            showCancelButton: false,
            confirmButtonText: "Close",
          });
          return;
        }
        if (surfaces.find((x) => x.id != id && x.value == values.value)) {
          Swal.fire({
            title: "Validation fail",
            text: `Value '${values.value}' is duplicated`,
            type: "error",
            showCancelButton: false,
            confirmButtonText: "Close",
          });
          return;
        }
        updateSurfaceDetail({ id: id, ...values, userId: selectedUser });
      },
    onRestForm:
      ({ surfaceDetail, reset, changeFieldValue }) =>
      () => {
        reset();
        if (surfaceDetail) {
          Object.keys(surfaceDetail).map((k) => {
            changeFieldValue(k, surfaceDetail[k]);
          });
        }
      },
  }),
  lifecycle({
    componentDidMount() {
      const surfaceId = get(this.props, "match.params.id");
      if (surfaceId) {
        this.props.setIsEdit(true);
        this.props.getSurfaceDetail(surfaceId);
      }
      if (!this.props.surfaces || !this.props.surfaces.length) {
        this.props.getData();
      }
    },
    componentDidUpdate() {
      const {
        surfaceState,
        surfaceDetail,
        changeFieldValue,
        saving,
        stateSaving,
        success,
      } = this.props;
      if (!surfaceState && surfaceDetail) {
        this.props.setSurfaceState(surfaceDetail);

        Object.keys(surfaceDetail).map((k) => {
          changeFieldValue(k, surfaceDetail[k]);
        });
      }

      if (
        stateSaving &&
        !saving.saving &&
        !get(this.props, "match.params.id") &&
        success
      ) {
        this.props.history.push("/surfaces");
      }
    },
    componentWillUnmount() {
      this.props.clearSurfaceDetail();
    },
  })
)(SurfaceForm);
