import moment from "moment";
import { authFetch } from "../../shared/utils/fetchUtils";
import {
  SetError,
  StartProcessing,
  UpdateProcessing,
  SetWarning,
  StopProcessing,
  ShowPermissionRequired,
} from "./SystemAction";
import { ApiEndpoint } from "../../env.config";
import { chunk } from "lodash";
import { get } from "lodash";
import {
  CompareArray,
  HasPermissions,
  Toast,
} from "../../shared/utils/objectExtensions";
import { MenuType, PermissionType } from "../../shared/utils/constant";
import imageCompression from "browser-image-compression";
import { GetSurfaces } from "./SurfaceAction";

export const RoadTypes = {
  LOADING: "LOADING",
  RESETLOADING: "RESETLOADING",
  UPLOADING: "UPLOADING",
  UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
  UPLOAD_SURVEYED_SUCCESS: "UPLOAD_SURVEYED_SUCCESS",
  GET_ROAD_INFO_SUCCESS: "GET_ROAD_INFO_SUCCESS",
  GET_ROAD_SURVEYED_SUCCESS: "GET_ROAD_SURVEYED_SUCCESS",
  SELECT_ROAD: "SELECT_ROAD",
  RENDER_ROAD_INFO: "RENDER_ROAD_INFO",
  SHOW_SURVEYED_LIST: "SHOW_SURVEYED_LIST",
  DELETED_SURVEYED: "DELETED_SURVEYED",
  DELETED_ROAD_FILE: "DELETED_ROAD_FILE",
  SELECT_DATE_SURVEYED: "SELECT_DATE_SURVEYED",
  GET_ATTRIBUTES_SUCCESS: "GET_ATTRIBUTES_SUCCESS",
  GET_REPAIRED_REPORT: "GET_REPAIRED_REPORT",
  GET_RATING_REPORT: "GET_RATING_REPORT",
  GET_DAMAGE_REPORT: "GET_DAMAGE_REPORT",
  GET_DETERIORATION_REPORT: "GET_DETERIORATION_REPORT",
  GET_REPAIRED_REPORT_SUCCESS: "GET_REPAIRED_REPORT_SUCCESS",
  GET_RATING_REPORT_SUCCESS: "GET_RATING_REPORT_SUCCESS",
  GET_DAMAGE_REPORT_SUCCESS: "GET_DAMAGE_REPORT_SUCCESS",
  GET_DETERIORATION_REPORT_SUCCESS: "GET_DETERIORATION_REPORT_SUCCESS",
  SET_RANK_FILTER: "SET_RANK_FILTER",
  SET_USER_FILTER: "SET_USER_FILTER",
  GET_ROADSURVEYED_FILE_SUCCESS: "GET_ROADSURVEYED_FILE_SUCCESS",
  GET_DATE_HAVE_DATA_SUCCESS: "GET_DATE_HAVE_DATA_SUCCESS",
  GET_CHECKED_POINTS_SUCCESS: "GET_CHECKED_POINTS_SUCCESS",
  CLEAR_DATE_SELECTED: "CLEAR_DATE_SELECTED",
  CLEAR_SURVEYED_DATA: "CLEAR_SURVEYED_DATA",
  DELETE_ROADSURVEYED_FILE_SUCCESS: "DELETE_ROADSURVEYED_FILE_SUCCESS",
  ERROR: "ERROR",
};

export const loading = () => ({
  type: RoadTypes.LOADING,
});
export const setGisloading = () => ({
  type: RoadTypes.LOADING,
});

export const resetLoading = () => ({
  type: RoadTypes.RESETLOADING,
});

export const uploading = () => ({
  type: RoadTypes.UPLOADING,
});

export const UploadSuccess = (json) => ({
  type: RoadTypes.UPLOAD_SUCCESS,
  geoJson: json,
});

export const UploadRoadSurveyedSuccess = (json) => ({
  type: RoadTypes.UPLOAD_SURVEYED_SUCCESS,
  data: json,
});

export const getRoadSuccess = (data) => ({
  type: RoadTypes.GET_ROAD_INFO_SUCCESS,
  data: data,
});

export const getRoadSurveyedSuccess = (data) => ({
  type: RoadTypes.GET_ROAD_SURVEYED_SUCCESS,
  data: data,
});

export const SelectRoad = (data) => ({
  type: RoadTypes.SELECT_ROAD,
  data: data,
});

export const deletedRoadFile = (id) => ({
  type: RoadTypes.DELETED_ROAD_FILE,
  id: id,
});

export const deletedRoadSurveyed = (id) => ({
  type: RoadTypes.DELETED_SURVEYED,
  id: id,
});

export const ShowRoadInfoInMap = () => ({
  type: RoadTypes.RENDER_ROAD_INFO,
});

export const SelectDateSurveyedSuccess = (dates, surveyedData) => ({
  type: RoadTypes.SELECT_DATE_SURVEYED,
  date: dates,
  data: surveyedData,
});

export const GetAttributesSuccess = (data) => ({
  type: RoadTypes.GET_ATTRIBUTES_SUCCESS,
  data: data,
});

export const ToggleShowAttributeList = () => ({
  type: RoadTypes.SHOW_SURVEYED_LIST,
});

export const GetRepairedReport = () => ({
  type: RoadTypes.GET_REPAIRED_REPORT,
});

export const GetRatingReport = () => ({
  type: RoadTypes.GET_RATING_REPORT,
});

export const GetDamageReport = () => ({
  type: RoadTypes.GET_DAMAGE_REPORT,
});

export const GetDeteriorationReport = () => ({
  type: RoadTypes.GET_DETERIORATION_REPORT,
});

export const GetRepairedReportSuccess = (data) => ({
  type: RoadTypes.GET_REPAIRED_REPORT_SUCCESS,
  data: data,
});

export const GetRatingReportSuccess = (data) => ({
  type: RoadTypes.GET_RATING_REPORT_SUCCESS,
  data: data,
});

export const GetDamageReportSuccess = (data) => ({
  type: RoadTypes.GET_DAMAGE_REPORT_SUCCESS,
  data: data,
});

export const GetDeteriorationReportSuccess = (data) => ({
  type: RoadTypes.GET_DETERIORATION_REPORT_SUCCESS,
  data: data,
});

export const GetRoadSurveyedFileSuccess = (data) => ({
  type: RoadTypes.GET_ROADSURVEYED_FILE_SUCCESS,
  data: data,
});

export const DeleteRoadSurveydFileSuccess = (id) => ({
  type: RoadTypes.DELETE_ROADSURVEYED_FILE_SUCCESS,
  id: id,
});

export const SetRankFilter = (data) => ({
  type: RoadTypes.SET_RANK_FILTER,
  filter: data,
});

export const SetUserFilter = (userId) => ({
  type: RoadTypes.SET_USER_FILTER,
  userId,
});

export const GetDateHaveDataSuccess = (data) => ({
  type: RoadTypes.GET_DATE_HAVE_DATA_SUCCESS,
  data: data,
});

export const GetCheckedPointSuccess = (data) => ({
  type: RoadTypes.GET_CHECKED_POINTS_SUCCESS,
  data: data,
});

export const ClearDateSelected = () => ({
  type: RoadTypes.CLEAR_DATE_SELECTED,
});

export const ClearSurveyedData = () => ({
  type: RoadTypes.CLEAR_SURVEYED_DATA,
});

export const RoadError = () => ({
  type: RoadTypes.ERROR,
});

const error = SetError;
const warning = SetWarning;

export function UploadRoadFile(file) {
  return function (dispatch) {
    dispatch(loading());

    const data = new FormData();
    data.append("file", file);
    return authFetch(`${ApiEndpoint}/Road/upload`, {
      method: "POST",
      body: data,
    })
      .then((json) => dispatch(UploadSuccess(json)))
      .catch((err) => {
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function SelectUserFilter(userId) {
  return function (dispatch) {
    dispatch(
      GetSurfaces(userId, () => {
        dispatch(SelectDateSurveyed(-1, null, userId));
      })
    );
    dispatch(SetUserFilter(userId));
  };
}

export function SelectDateSurveyed(idx, date, user = null) {
  return function (dispatch, getState) {
    dispatch(loading());

    const { showAttributeList, selectedDates, userFilter } = getState().road;
    const { isAdmin } = getState().auth;
    var dates = JSON.parse(JSON.stringify(selectedDates));
    if (date) {
      if (idx === dates.length) {
        dates.push(date);
      } else {
        dates = dates.map((x, index) => {
          if (index === idx) x = date;
          return x;
        });
      }
    } else {
      dates = dates.filter((x, index) => index !== idx);
    }
    dispatch(SelectDateSurveyedSuccess(dates, null));

    const userId = isAdmin ? user || userFilter : null;

    if (showAttributeList && dates.length > 0) {
      authFetch(`${ApiEndpoint}/Road/attribute${userId ? `/${userId}` : ""}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dates.map((x) => moment(x).format("DDMMYYYY"))),
      })
        .then((json) => dispatch(GetAttributesSuccess(json)))
        .catch((err) => {
          dispatch(RoadError());
          dispatch(error(err));
        });
    }

    if (dates) {
      return authFetch(
        `${ApiEndpoint}/Road/surveyed/date${userId ? `/${userId}` : ""}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dates.map((x) => moment(x).format("DDMMYYYY"))),
        }
      )
        .then((json) => {
          if (json) {
            Promise.all(
              json.map((item) => {
                return GetSnappedPoint(item.polylines);
              })
            ).then((results) => {
              json.map((x, idx) => {
                x.snappedPoints = results[idx];
                return x;
              });
              dispatch(SelectDateSurveyedSuccess(dates, json));
            });
          }
          dispatch(RoadError());
        })
        .catch((err) => {
          dispatch(RoadError());
          dispatch(error(err));
        });
    } else {
      dispatch(resetLoading());
    }
  };
}

export function UploadSurveyedData(dataFile, ImageFiles, userId = null) {
  return function (dispatch, getState) {
    var uploadImages = ImageFiles;

    if (
      ImageFiles &&
      ImageFiles.length > 0 &&
      dataFile &&
      dataFile.length == 1
    ) {
      dispatch(uploading());
      dispatch(StartProcessing(1));
      const compressOptions = {
        initialQuality: 0.5,
      };
      Promise.all(
        ImageFiles.map((file) => imageCompression(file, compressOptions))
      )
        .then((results) => {
          uploadImages = results;
          dataFile = dataFile[0];
          const { selectedDates } = getState().road;
          if (!dataFile && (!selectedDates || selectedDates.length === 0))
            return dispatch(warning("Please select a date to update images"));
          if (selectedDates && selectedDates.length > 1)
            return dispatch(warning("Please choose a date to update images"));

          const selectedDate = selectedDates[0];
          dispatch(uploading());
          const taskFiles = [];
          taskFiles.push(...chunk(uploadImages, 20));
          dispatch(StartProcessing(taskFiles.length + (dataFile ? 1 : 0)));

          if (dataFile) {
            const data = new FormData();
            data.append("files[]", dataFile, dataFile.name);
            return authFetch(
              userId
                ? `${ApiEndpoint}/Road/uploadsurvey/user/${userId}${
                    selectedDate
                      ? "/" + moment(selectedDate).format("DDMMYYYY")
                      : ""
                  }`
                : `${ApiEndpoint}/Road/uploadsurvey/${
                    selectedDate ? moment(selectedDate).format("DDMMYYYY") : ""
                  }`,
              {
                method: "POST",
                headers: { Accept: "application/json" },
                body: data,
              }
            )
              .then((json) => {
                dispatch(UpdateProcessing());
                Promise.all(
                  json.map((item) => {
                    return GetSnappedPoint(item.polylines);
                  })
                ).then((results) => {
                  json.map((x, idx) => {
                    x.snappedPoints = results[idx];
                    return x;
                  });
                  dispatch(UploadRoadSurveyedSuccess(json));
                });

                const surveyedDate = get(json, "[0].surveyedDate", null);
                if (surveyedDate) {
                  return UploadParallelImages(dispatch, taskFiles, surveyedDate)
                    .then(() => dispatch(RoadError()))
                    .catch((err) => {
                      dispatch(StopProcessing());
                      dispatch(RoadError());
                      dispatch(error(err));
                    });
                }
              })
              .catch((err) => {
                dispatch(StopProcessing());
                dispatch(RoadError());
                dispatch(error(err));
              });
          }

          return UploadParallelImages(dispatch, taskFiles, selectedDate)
            .then(() => dispatch(RoadError()))
            .catch((err) => {
              dispatch(StopProcessing());
              dispatch(RoadError());
              dispatch(error(err));
            });
        })
        .catch((e) => console.log(e));
    } else {
      const { selectedDates } = getState().road;
      if (!dataFile && (!selectedDates || selectedDates.length === 0))
        return dispatch(warning("Please select a date to update images"));
      if (selectedDates && selectedDates.length > 1)
        return dispatch(warning("Please choose a date to update images"));

      const selectedDate = selectedDates[0];
      dispatch(uploading());
      const taskFiles = [];
      taskFiles.push(...chunk(uploadImages, 20));
      dispatch(StartProcessing(taskFiles.length + (dataFile ? 1 : 0)));

      if (dataFile) {
        if (dataFile.length > 1) {
          dispatch(StartProcessing(dataFile.length));
          let errors = [];
          return UploadParallelSurveyFiles(
            dispatch,
            userId
              ? `${ApiEndpoint}/Road/uploadsurvey/user/${userId}${
                  selectedDate
                    ? "/" + moment(selectedDate).format("DDMMYYYY")
                    : ""
                }`
              : `${ApiEndpoint}/Road/uploadsurvey/${
                  selectedDate ? moment(selectedDate).format("DDMMYYYY") : ""
                }`,
            dataFile,
            errors
          )
            .then((json) => {
              dispatch(
                UploadRoadSurveyedSuccess(
                  json && json.length > 0
                    ? json.find((x) => x && x.length > 0)
                    : []
                )
              );

              if (errors.length > 0) {
                dispatch(
                  error({
                    statusText: "Error",
                    message: `<div style="text-align: left;"> ${errors.join(
                      "<br/>"
                    )} </div>`,
                  })
                );
              }
            })
            .catch((err) => {
              dispatch(StopProcessing());
              dispatch(RoadError());
              dispatch(error(err));
            });
        } else {
          const data = new FormData();
          data.append("files[]", dataFile[0], dataFile[0].name);
          return authFetch(
            userId
              ? `${ApiEndpoint}/Road/uploadsurvey/user/${userId}${
                  selectedDate
                    ? "/" + moment(selectedDate).format("DDMMYYYY")
                    : ""
                }`
              : `${ApiEndpoint}/Road/uploadsurvey/${
                  selectedDate ? moment(selectedDate).format("DDMMYYYY") : ""
                }`,
            {
              method: "POST",
              headers: { Accept: "application/json" },
              body: data,
            }
          )
            .then((json) => {
              dispatch(UpdateProcessing());
              Promise.all(
                json.map((item) => {
                  return GetSnappedPoint(item.polylines);
                })
              ).then((results) => {
                json.map((x, idx) => {
                  x.snappedPoints = results[idx];
                  return x;
                });
                dispatch(UploadRoadSurveyedSuccess(json));
              });

              const surveyedDate = get(json, "[0].surveyedDate", null);
              if (surveyedDate) {
                return UploadParallelImages(dispatch, taskFiles, surveyedDate)
                  .then(() => dispatch(RoadError()))
                  .catch((err) => {
                    dispatch(StopProcessing());
                    dispatch(RoadError());
                    dispatch(error(err));
                  });
              }
            })
            .catch((err) => {
              dispatch(StopProcessing());
              dispatch(RoadError());
              dispatch(error(err));
            });
        }
      }

      return UploadParallelImages(dispatch, taskFiles, selectedDate)
        .then(() => dispatch(RoadError()))
        .catch((err) => {
          dispatch(StopProcessing());
          dispatch(RoadError());
          dispatch(error(err));
        });
    }
  };
}

function UploadParallelSurveyFiles(dispatch, endPointurl, taskFiles, errors) {
  return new Promise(async (resolve, reject) => {
    let results = [];
    for (let i = 0; i < taskFiles.length; i++) {
      const dataFile = taskFiles[i];
      try {
        const data = new FormData();
        data.append("files[]", dataFile, dataFile.name);
        const response = await authFetch(endPointurl, {
          method: "POST",
          headers: { Accept: "application/json" },
          body: data,
        });
        dispatch(UpdateProcessing());
        if (response.message) {
          errors.push(
            "<b>- File " + dataFile.name + "</b>: " + response.message
          );
        } else {
          results.push(response);
        }
      } catch (err) {
        console.log("err", err);
        dispatch(UpdateProcessing());
        errors.push("<b>- File " + dataFile.name + "</b>: " + err?.message);
      }
    }
    resolve(results);
  });
}

function UploadParallelImages(dispatch, taskFiles, selectedDate) {
  return new Promise((resolve, reject) => {
    Promise.all(
      taskFiles.map((files) => {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          for (const file of files) {
            if (file) data.append("files[]", file, file.name);
          }
          return authFetch(
            `${ApiEndpoint}/Road/uploadsurvey/${
              selectedDate ? moment(selectedDate).format("DDMMYYYY") : ""
            }`,
            {
              method: "POST",
              headers: { Accept: "application/json" },
              body: data,
            }
          )
            .then((json) => {
              dispatch(UpdateProcessing());
              resolve(json);
            })
            .catch((err) => reject(err.message));
        });
      })
    )
      .then((results) => resolve(results))
      .catch((err) => reject({ statusText: "Error", message: err }));
  });
}

export function GetRoadFiles() {
  return function (dispatch) {
    dispatch(loading());

    return authFetch(`${ApiEndpoint}/Road`, { method: "GET" })
      .then((json) => dispatch(getRoadSuccess(json)))
      .catch((err) => {
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function GetRoadSurveyedFiles(data) {
  return function (dispatch) {
    dispatch(loading());

    return authFetch(`${ApiEndpoint}/Road/surveyedfiles`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((json) => dispatch(GetRoadSurveyedFileSuccess(json)))
      .catch((err) => {
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function GetRoadSurveyedFilesPromise(data) {
  return authFetch(`${ApiEndpoint}/Road/surveyedfiles`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function GetRoadSurveyed(fileId = null, userId = null) {
  return function (dispatch, getState) {
    const { selectedDates } = getState().road;
    if (!fileId && selectedDates.length > 0) {
      return dispatch(SelectDateSurveyed(-1, null));
    }
    return authFetch(
      `${ApiEndpoint}/Road/surveyed${userId ? `/${userId}` : ""}${
        fileId ? `/file/${fileId}` : ""
      }`,
      { method: "GET" }
    )
      .then((json) => {
        // const dataByRoad = groupBy(json, 'routeName');
        // dispatch(getRoadSurveyedSuccess(json))})
        if (json) {
          Promise.all(
            json.map((item) => {
              return GetSnappedPoint(item.polylines);
            })
          ).then((results) => {
            json.map((x, idx) => {
              x.snappedPoints = results[idx];
              return x;
            });
            dispatch(getRoadSurveyedSuccess(json));
          });
        }
      })
      .catch((err) => {
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function GetRoadInfor(file) {
  return function (dispatch) {
    dispatch(SelectRoad(file));

    if (file) {
      return authFetch(`${ApiEndpoint}/Road/road/file/${file.id}`, {
        method: "GET",
      })
        .then((json) => dispatch(UploadSuccess(json)))
        .catch((err) => {
          dispatch(RoadError());
          dispatch(error(err));
        });
    }
  };
}

export function DeleteRoadFile(id) {
  return function (dispatch) {
    return authFetch(`${ApiEndpoint}/Road/${id}`, { method: "DELETE" })
      .then(() => dispatch(deletedRoadFile(id)))
      .catch((err) => {
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function DeleteRoadSurveydFile(id, callback = null) {
  return function (dispatch) {
    if (!HasPermissions(MenuType.SurveyedList, PermissionType.Delete)) {
      return dispatch(ShowPermissionRequired());
    }
    dispatch(loading());
    return authFetch(`${ApiEndpoint}/Road/surveyedfile/${id}`, {
      method: "DELETE",
    })
      .then(() => {
        if (callback) {
          callback();
        }
        dispatch(DeleteRoadSurveydFileSuccess(id));
        dispatch(resetLoading());
        Toast.fire({ type: "success", title: "Deleted" });
      })
      .catch((err) => {
        dispatch(resetLoading());
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function DeleteRoadSurveyed(id) {
  return function (dispatch) {
    dispatch(loading());

    return authFetch(`${ApiEndpoint}/Road/surveyed/${id}`, { method: "DELETE" })
      .then(() => dispatch(deletedRoadSurveyed(id)))
      .catch((err) => {
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function ShowAttributeList() {
  return function (dispatch, getState) {
    const { showAttributeList, selectedDates, userFilter } = getState().road;
    const { isAdmin } = getState().auth;

    if (!showAttributeList) {
      if (!selectedDates || selectedDates.length === 0)
        return dispatch(warning("Please select a date!"));

      dispatch(loading());
      const userId = isAdmin ? userFilter : null;
      return authFetch(
        `${ApiEndpoint}/Road/attribute${userId ? `/${userId}` : ""}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(
            selectedDates.map((x) => moment(x).format("DDMMYYYY"))
          ),
        }
      )
        .then((json) => {
          if (json && json.length === 0)
            return dispatch(warning("There are no data to show"));
          dispatch(GetAttributesSuccess(json));
        })
        .catch((err) => {
          if (err.statusCode === 204)
            dispatch(warning("There are no data to show"));
          else {
            dispatch(RoadError());
            dispatch(error(err));
          }
        });
    }
    return dispatch(ToggleShowAttributeList());
  };
}

export function GetDashboardReport() {
  return function (dispatch) {
    dispatch(loading());

    return new Promise((resolve, reject) => {
      Promise.all([
        new Promise(() => {
          dispatch(GetRepairedReport());
          return authFetch(`${ApiEndpoint}/Report/repaired`, { method: "GET" })
            .then((json) => dispatch(GetRepairedReportSuccess(json)))
            .catch((err) => {
              dispatch(RoadError());
              dispatch(error(err));
            });
        }),
        new Promise(() => {
          dispatch(GetRatingReport());
          return authFetch(`${ApiEndpoint}/Report/rating`, { method: "GET" })
            .then((json) => dispatch(GetRatingReportSuccess(json)))
            .catch((err) => {
              dispatch(RoadError());
              dispatch(error(err));
            });
        }),
        new Promise(() => {
          dispatch(GetDamageReport());
          return authFetch(`${ApiEndpoint}/Report/damage`, { method: "GET" })
            .then((json) => {
              Promise.all(
                json.map((item) => {
                  return GetSnappedPoint(item.polylines);
                })
              ).then((results) => {
                json.map((x, idx) => {
                  x.snappedPoints = results[idx];
                  return x;
                });
                dispatch(GetDamageReportSuccess(json));
              });
            })
            .catch((err) => {
              dispatch(RoadError());
              dispatch(error(err));
            });
        }),
        new Promise(() => {
          dispatch(GetDistressReport(""));
        }),
      ])
        .then((results) => resolve(results))
        .catch((err) => reject({ statusText: "Error", message: err }));
    });
  };
}

export function GetDistressReport(roadName) {
  return function (dispatch) {
    dispatch(GetDeteriorationReport());
    return authFetch(`${ApiEndpoint}/Report/deterioration/${roadName}`)
      .then((json) => dispatch(GetDeteriorationReportSuccess(json)))
      .catch((err) => {
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

export function FilterRank(distress) {
  return function (dispatch, getState) {
    const { rankFilters } = getState().road;

    var newListFilters = !distress
      ? []
      : JSON.parse(JSON.stringify(rankFilters));
    if (distress) {
      const existing = rankFilters.find((x) => CompareArray(x, distress));
      if (existing) {
        newListFilters = newListFilters.filter(
          (x) => !CompareArray(x, distress)
        );
      } else {
        newListFilters.push(distress);
      }
    }
    dispatch(SetRankFilter(newListFilters));
  };
}

export function GetDateThatHaveData(month, year) {
  return function (dispatch, getState) {
    const { userFilter } = getState().road;
    return authFetch(
      `${ApiEndpoint}/Road/${userFilter}/date/data/${month}/${year}`,
      { method: "GET" }
    )
      .then((json) => dispatch(GetDateHaveDataSuccess(json)))
      .catch((err) => {
        dispatch(error(err));
      });
  };
}

export function ResetFilterDate() {
  return function (dispatch) {
    dispatch(loading());

    dispatch(ClearDateSelected());
    return authFetch(`${ApiEndpoint}/Road/surveyed`, { method: "GET" })
      .then((json) => dispatch(getRoadSurveyedSuccess(json)))
      .catch((err) => {
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}

function GetSnappedPoint(polylines) {
  return new Promise((resolve, reject) => {
    resolve(polylines);
  });
}

export function SaveCheckedPoints(points) {
  return function (dispatch) {
    dispatch(GetCheckedPointSuccess(points));
    return authFetch(`${ApiEndpoint}/Road/surveyed/checked`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(points),
    });
  };
}

export function GetCheckedPoints() {
  return function (dispatch) {
    return authFetch(`${ApiEndpoint}/Road/surveyed/checked`, { method: "GET" })
      .then((json) => dispatch(GetCheckedPointSuccess(json)))
      .catch((err) => {
        dispatch(RoadError());
        dispatch(error(err));
      });
  };
}
