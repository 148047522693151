import { authFetch } from "../../shared/utils/fetchUtils";
import {
  SetError,
  SetSuccess,
  StartProcessing,
  StopProcessing,
} from "./SystemAction";
import { ApiEndpoint } from "../../env.config";

export const SurfaceTypes = {
  FETCHING: "surface/FETCHING",
  SAVING: "surface/SAVING",
  SAVED: "surface/SAVED",
  DELETED: "surface/DELETED",
  GET_SUCCESS: "surface/GET_SUCCESS",
  GET_DETAIL_SUCCESS: "surface/GET_DETAIL_SUCCESS",
  ERROR: "surface/ERROR",
  CLEAR_ERROR: "surface/CLEAR_ERROR",
  CLEAR_DATA: "surface/CLEAR_DATA",
  SET_USER: "surface/SET_USER",
};

const Fetching = () => ({
  type: SurfaceTypes.FETCHING,
});

const Saving = () => ({
  type: SurfaceTypes.SAVING,
});

const Saved = () => ({
  type: SurfaceTypes.SAVED,
});

const Deleted = (id) => ({
  type: SurfaceTypes.DELETED,
  id,
});

const Error = () => ({
  type: SurfaceTypes.ERROR,
});

const GetSurfaceSuccess = (data) => ({
  type: SurfaceTypes.GET_SUCCESS,
  data,
});

const GetSurfaceDetailSuccess = (data) => ({
  type: SurfaceTypes.GET_DETAIL_SUCCESS,
  data,
});

const SetSystemError = SetError;

export const ClearSurfaceData = () => ({
  type: SurfaceTypes.CLEAR_DATA,
});

export const SetSelectedUser = (userId) => ({
  type: SurfaceTypes.SET_USER,
  userId,
});

export function GetSurfaces(userId, callback = null) {
  return function (dispatch) {
    dispatch(Fetching());

    return authFetch(
      `${ApiEndpoint}/surface${userId ? `?userId=${userId}` : ""}`,
      { method: "GET" }
    )
      .then((json) => {
        dispatch(GetSurfaceSuccess(json));
        if (callback) {
          callback();
        }
      })
      .catch((errObj) => {
        dispatch(Error());
        dispatch(SetSystemError(errObj));
      });
  };
}

export function GetSurfaceDetail(id) {
  return function (dispatch) {
    dispatch(Fetching());

    return authFetch(`${ApiEndpoint}/surface/${id}`, { method: "GET" })
      .then((json) => dispatch(GetSurfaceDetailSuccess(json)))
      .catch((errObj) => {
        dispatch(Error());
        dispatch(SetSystemError(errObj));
      });
  };
}

export function UpdateSurfaceDetail(data) {
  return function (dispatch) {
    dispatch(Saving());

    return authFetch(`${ApiEndpoint}/surface`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((_json) => {
        dispatch(Saved());
        dispatch(SetSuccess("Save successfully!"));
      })
      .catch((errObj) => {
        dispatch(Saved());
        dispatch(Error());
        dispatch(SetSystemError(errObj));
      });
  };
}

export function DeleteSurface(id) {
  return function (dispatch) {
    dispatch(Saving());
    dispatch(StartProcessing(2));
    return authFetch(`${ApiEndpoint}/surface/${id}`, { method: "DELETE" })
      .then((_json) => {
        dispatch(StopProcessing());
        dispatch(Deleted(id));
        dispatch(SetSuccess("Delete successfully!"));
      })
      .catch((errObj) => {
        dispatch(StopProcessing());
        dispatch(Saved());
        dispatch(Error());
        dispatch(SetSystemError(errObj));
      });
  };
}
