import React, { useRef } from "react";
import { Map as LeafletMap, Popup, Polyline, Circle } from "react-leaflet";
import L from "leaflet";
import { get, isArray } from "lodash";
import { connect } from "react-redux";

import {
  BaseMapTile,
  MAIN_COLOR,
  MenuType,
  PermissionType,
} from "../../shared/utils/constant";
import FullscreenControl from "react-leaflet-fullscreen";
import SurveyedDetail from "./LeftContent/SurveyedDetail";
import PinIcon from "../../shared/components/PinIcon";
import { DriftMarker as Marker } from "leaflet-drift-marker";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { geolocated } from "react-geolocated";
import {
  getDistanceFromLatLonInKm,
  coordinateToDistance,
  getGeoJsonFromSurveyedData,
  HasPermissions,
} from "../../shared/utils/objectExtensions";
import Control from "react-leaflet-control";
import { Button, ButtonGroup, Tooltip } from "@material-ui/core";
import { withTranslation, Trans } from "react-i18next";
import {
  CloudDownload as DownloadIcon,
  HdrStrong as ResetCheckedPointsIcon,
} from "@material-ui/icons";
import { setSurveyedMapType } from "../../services/actions/GisAction";
import shpwrite from "shp-write";
import moment from "moment";
import { ShowPermissionRequired } from "../../services/actions/SystemAction";
import {
  GetCheckedPoints,
  SaveCheckedPoints,
} from "../../services/actions/RoadAction";

const Map = ({
  surveyedAllocated,
  showSurveyedDetail,
  dataDetailSelected,
  closeSurveyedDetail,
  coords,
  showAttributeList,
  rankFilters,
  shouldShowDetail,
  surveyedMapType,
  setMapType,
  dowloadShapeFile,
  checkedPoints,
  updateCheckedPoint,
  resetCheckedPoints,
  forceReloadMap,
  setForceReloadMap,
  surfaces,
  visiblePointView,
  setVisiblePointView,
  currentZoom,
  setCurrentZoom,
}) => {
  const mapEl = useRef(null);
  const userPosition = coords
    ? [get(coords, "latitude"), get(coords, "longitude")]
    : [30.55435, -91.03677];

  var selectedPoint = null;
  if (
    dataDetailSelected &&
    dataDetailSelected.startPosition &&
    surveyedAllocated
  ) {
    const startPos = dataDetailSelected.startPosition;
    surveyedAllocated.map((x) => {
      x.polylines.map((p, idx) => {
        if (p[0] == startPos[0] && p[1] == startPos[1]) {
          selectedPoint = x.polylines[idx];
        }
      });
    });
  }
  var center = selectedPoint
    ? selectedPoint
    : surveyedAllocated && surveyedAllocated.length > 0
    ? surveyedAllocated[0].polylines[0]
    : userPosition
    ? userPosition
    : [0, 0];
  if (!shouldShowDetail && rankFilters && rankFilters.length > 0) {
    const focusToThis = surveyedAllocated?.find((x) =>
      rankFilters.find((r) => r.includes(x.distressAverage))
    );
    if (focusToThis) center = focusToThis.polylines[0];
  }
  const zoom = center[0] == 30.55435 ? 5 : shouldShowDetail ? 18 : 16;

  const surveyedData =
    surveyedAllocated &&
    isArray(surveyedAllocated) &&
    (surveyedMapType === "point"
      ? surveyedAllocated.filter((x, _) => {
          const isMatchDistress = x.polylines.some((p, idx) => {
            const pointDistress = x.polylineDistress.find(
              (d) => d.polylines === JSON.stringify(p)
            );

            return (
              rankFilters &&
              rankFilters.length > 0 &&
              rankFilters.find((r) =>
                r.includes(
                  pointDistress
                    ? pointDistress.distressAverage
                    : x.distressAverage
                )
              )
            );
          });

          return (
            x.polylines &&
            x.polylines.length > 1 &&
            (rankFilters.length === 0 || (rankFilters && isMatchDistress)) &&
            ![9, 10].includes(x.distressAverage)
          );
        })
      : surveyedAllocated.filter(
          (x, _) =>
            x.polylines &&
            x.polylines.length > 1 &&
            (rankFilters.length === 0 ||
              (rankFilters &&
                rankFilters.find((r) => r.includes(x.distressAverage)))) &&
            ![9, 10].includes(x.distressAverage)
        ));

  const colorByDistress = (value) => {
    var condition = (surfaces || []).find((x) => x.value == value);
    if (condition) {
      return condition.color;
    }

    return MAIN_COLOR;
  };

  return (
    <LeafletMap
      ref={mapEl}
      center={center}
      zoom={currentZoom}
      maxZoom={18}
      style={{
        height: `calc(100vh - ${
          showAttributeList
            ? window.innerHeight < 700
              ? "264px"
              : "364px"
            : "64px"
        })`,
      }}
      onPopupClose={() => closeSurveyedDetail()}
      boundsOptions={
        shouldShowDetail && {
          paddingBottomRight: [250, 0],
        }
      }
      dragging={!shouldShowDetail}
      onZoomEnd={(e) => {
        if (e.target._zoom < 17) {
          setMapType("line");
        }
        setVisiblePointView(e.target._zoom >= 17);
        setForceReloadMap(true);
        setCurrentZoom(e.target._zoom);
      }}
      onDragEnd={() => setForceReloadMap(true)}
    >
      <BaseMapTile />
      <FullscreenControl position="topright" />
      <Control position="topright">
        <ButtonGroup size="small" className="map-panel-type">
          <Button
            className={`${surveyedMapType === "line" ? "type-selected" : ""}`}
            onClick={() => setMapType("line")}
          >
            Line
          </Button>
          <Button
            className={`${surveyedMapType === "point" ? "type-selected" : ""}`}
            onClick={() => setMapType("point")}
            disabled={!visiblePointView}
          >
            Point
          </Button>
        </ButtonGroup>
      </Control>
      {surveyedData && surveyedData.length > 0 && (
        <Control position="topright">
          <Button
            variant="outlined"
            size="small"
            className="btn-download-shape-file"
            onClick={dowloadShapeFile}
          >
            <Tooltip title={<Trans>download_shapefile</Trans>} aria-label="add">
              <DownloadIcon />
            </Tooltip>
          </Button>
        </Control>
      )}
      {surveyedMapType === "point" && checkedPoints.length > 0 && (
        <Control position="topright">
          <Button
            variant="outlined"
            size="small"
            className="btn-download-shape-file"
            onClick={() => resetCheckedPoints()}
          >
            <Tooltip
              title={<Trans>reset_checked_points</Trans>}
              aria-label="add"
            >
              <ResetCheckedPointsIcon />
            </Tooltip>
          </Button>
        </Control>
      )}
      {shouldShowDetail && dataDetailSelected && (
        <Marker
          className="popup-road-detail"
          ref={(el) => {
            if (el && el.leafletElement)
              window.setTimeout(() => el.leafletElement.openPopup());
          }}
          icon={PinIcon}
          position={center}
          duration={1000}
        >
          <Popup
            className={`popup-road-detail ${
              surveyedMapType === "line" ? "" : "popup-content-point"
            }`}
          >
            <SurveyedDetail
              {...dataDetailSelected}
              showSurveyedDetail={(
                startPos,
                startDate,
                polylines,
                lastPositionData
              ) => {
                updateCheckedPoint(startPos);
                showSurveyedDetail(
                  startPos,
                  startDate,
                  polylines,
                  surveyedData,
                  lastPositionData
                );
              }}
              surveyedData={surveyedData}
            />
          </Popup>
        </Marker>
      )}
      {!forceReloadMap && (
        <>
          {surveyedData &&
            surveyedData.map((x, idx) => {
              if (!mapEl.current) return <></>;
              const startPos = x.polylines[0];
              const endPos = x.polylines[x.polylines.length - 1];
              const map = mapEl.current.leafletElement;
              if (
                map
                  .getBounds()
                  .contains(new L.latLng(startPos[0], startPos[1])) ||
                map.getBounds().contains(new L.latLng(endPos[0], endPos[1]))
              ) {
                const distance = getDistanceFromLatLonInKm(
                  startPos[0],
                  startPos[1],
                  endPos[0],
                  endPos[1]
                );
                const totalDistance = coordinateToDistance(x.polylines);
                return surveyedMapType === "line" ? (
                  <Polyline
                    key={idx}
                    color={colorByDistress(x.distressAverage)}
                    positions={
                      totalDistance - distance < 0.0003
                        ? [startPos, endPos]
                        : x.polylines
                    }
                    onclick={() => {
                      updateCheckedPoint(x.polylines[0]);
                      showSurveyedDetail(
                        x.polylines[0],
                        x.surveyedDate,
                        x.polylines,
                        surveyedData
                      );
                    }}
                  />
                ) : (
                  x.polylines.map((p, idx) => {
                    const pointDistress = x.polylineDistress.find(
                      (d) => d.polylines === JSON.stringify(p)
                    );
                    if (
                      rankFilters &&
                      rankFilters.length > 0 &&
                      !rankFilters.find((r) =>
                        r.includes(
                          pointDistress
                            ? pointDistress.distressAverage
                            : x.distressAverage
                        )
                      )
                    ) {
                      return <div key={idx}></div>;
                    }

                    const color = colorByDistress(
                      pointDistress
                        ? pointDistress.distressAverage
                        : x.distressAverage
                    );
                    const isConnectionPoint = () => {
                      const exist = surveyedData.filter((s) =>
                        s.polylines.find(
                          (po) => JSON.stringify(po) === JSON.stringify(p)
                        )
                      );
                      return exist && exist.length > 1 ? true : false;
                    };
                    return (
                      <Circle
                        key={idx}
                        className={`point-surveyed-type ${
                          checkedPoints.find(
                            (x) => JSON.stringify(x) === JSON.stringify(p)
                          )
                            ? `checked ${
                                isConnectionPoint() ? "connection" : ""
                              }`
                            : ""
                        }`}
                        onclick={() => {
                          updateCheckedPoint(p);
                          showSurveyedDetail(
                            p,
                            x.surveyedDate,
                            x.polylines,
                            surveyedData
                          );
                        }}
                        center={p}
                        radius={1.5}
                        color={color}
                        fillColor={color}
                      ></Circle>
                    );
                  })
                );
              }
            })}
        </>
      )}
    </LeafletMap>
  );
};

export default compose(
  withTranslation("translations"),
  withState("forceReloadMap", "setForceReloadMap", false),
  withState("visiblePointView", "setVisiblePointView", false),
  withState("currentZoom", "setCurrentZoom", 16),
  geolocated({
    positionOptions: { enableHighAccuracy: false },
    userDecisionTimeout: 5000,
  }),
  connect(
    (state) => ({
      surveyedAllocated: state.road.surveyedAllocated,
      showAttributeList: state.road.showAttributeList,
      rankFilters: state.road.rankFilters,
      surveyedMapType: state.gis.surveyedMapType,
      loading: state.gis.loading,
      checkedPoints: state.road.checkedPoints,
      surfaces: state.surface.surfaces,
    }),
    (dispatch) => ({
      setMapType: (value) => dispatch(setSurveyedMapType(value)),
      showPermissionRequired: () => dispatch(ShowPermissionRequired()),
      getCheckedPoint: () => dispatch(GetCheckedPoints()),
      saveCheckedPoint: (points) => dispatch(SaveCheckedPoints(points)),
    })
  ),
  withHandlers({
    dowloadShapeFile:
      ({
        surveyedMapType,
        surveyedAllocated,
        rankFilters,
        showPermissionRequired,
        surfaces,
      }) =>
      () => {
        if (!HasPermissions(MenuType.GIS, PermissionType.Download)) {
          return showPermissionRequired();
        }
        const surveyedData =
          surveyedAllocated &&
          isArray(surveyedAllocated) &&
          surveyedAllocated.filter(
            (x, idx) =>
              x.polylines &&
              x.polylines.length > 1 &&
              (rankFilters.length === 0 ||
                (rankFilters &&
                  rankFilters.find((r) => r.includes(x.distressAverage)))) &&
              ![9, 10].includes(x.distressAverage)
          );
        const fileName = `pms_shapefile_${
          surveyedMapType === "line" ? "line_" : "point_"
        }${moment().format("DDMMYYYYhhmmss")}`;
        var options = {
          file: fileName,
          folder: fileName,
          types: {
            point: "points",
            polygon: "polygons",
            polyline: "polylines",
          },
        };
        shpwrite.download(
          {
            type: "FeatureCollection",
            features: [
              ...getGeoJsonFromSurveyedData(
                surfaces,
                surveyedData,
                surveyedMapType
              ),
            ],
          },
          options
        );
      },
    updateCheckedPoint:
      ({ saveCheckedPoint, checkedPoints }) =>
      (point) => {
        var pointsSelected = JSON.parse(JSON.stringify(checkedPoints));
        if (
          !checkedPoints.find(
            (x) => JSON.stringify(x) === JSON.stringify(point)
          )
        ) {
          pointsSelected.push(point);
          saveCheckedPoint(pointsSelected);
        }
      },
    resetCheckedPoints:
      ({ saveCheckedPoint }) =>
      () => {
        saveCheckedPoint([]);
      },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getCheckedPoint();
    },
    componentWillUpdate(nextProps) {
      try {
        if (
          JSON.stringify(this.props.surveyedAllocated) !==
          JSON.stringify(nextProps.surveyedAllocated)
        ) {
          this.props.closeSurveyedDetail(true);
          this.props.setForceReloadMap(true);
        }
        if (
          JSON.stringify(nextProps.checkedPoints) !==
          JSON.stringify(this.props.checkedPoints)
        ) {
          this.props.setForceReloadMap(true);
        }
      } catch (_err) {
        //ignored
      }
    },
    componentDidUpdate(prevProps) {
      if (this.props.forceReloadMap) this.props.setForceReloadMap(false);
      try {
        if (
          JSON.stringify(prevProps.rankFilters) !==
          JSON.stringify(this.props.rankFilters)
        ) {
          this.props.setForceReloadMap(true);
        }
      } catch (_err) {
        //ignored
      }
    },
  })
)(Map);
